;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"2020b4e19b0ea8291bc4ef2f995a4e92cca6a442"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'
import { version } from './package.json'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT

Sentry.init({
  dsn: SENTRY_DSN,
  release: `v${version}`,
  environment: ENVIRONMENT,
  maxValueLength: 2000,
  enabled: ENVIRONMENT === 'prod',
})

Sentry.setTag('side', 'server')
